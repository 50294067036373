//@ts-nocheck
import React from 'react';
import './CosmicOrbitLoader.css'; // Make sure this import matches the name of your CSS file.
import Spinner from './bootstrap/Spinner';
const CosmicOrbitLoader = () => (
    <div className='reactApp_cosmicOrbitLoader'>
        {/* <div className='reactApp_cosmicOrbitLoader_centerPoint'></div> 
        <div className='reactApp_cosmicOrbitLoader_planet'></div>
        <div className='reactApp_cosmicOrbitLoader_planet'></div>
        <div className='reactApp_cosmicOrbitLoader_planet'></div> */}
        <Spinner size="8rem" color="danger" />
    </div>
);


export default CosmicOrbitLoader;

