import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { autorun } from "mobx";
import notificationState from "../../../shared/mobx/notification-state";
import { ToastProvider, useToasts } from 'react-toast-notifications';

const NotificationLogic = observer(() => {
  const { addToast } = useToasts();
  useEffect(() => {
    const disposer = autorun(() => {
      const notification = notificationState.activeNotifictions[0];
      if (notification) {
        addToast(notification.message, {
          appearance: notification.type,
          autoDismiss: true,
          autoDismissTimeout:3000
        });
        notificationState.setNotificationDisplayed(notification.id);
      }
    });

    return () => disposer();
  }, [addToast]);

  return null;
});

const NotificationToast = () => {
  return (
    <ToastProvider>
      <NotificationLogic />
    </ToastProvider>
  );
};

export default NotificationToast;
