import { Paddle } from '@paddle/paddle-js';
// @ts-nocheck
import { apiClient } from '../base-classes/apiBaseClass';
import { makeAutoObservable } from "mobx";
import notificationState from './notification-state';

interface email_config {
    username: string;
    password: string;
    host: string;
    port: string;
    email_from?: string;
}

interface paddle_config {
    paddle_url: string;
    paddle_api_key: string;
}

interface paddle_client_config {
    client_key: string;
}


interface pdf_config {
    watermark: string,
    daily_limit: number,
    maximum_pages_allowed: any,
}
export interface IConfigValues {
    email_config: email_config;
    paddle_config: paddle_config;
    paddle_client_config: paddle_client_config;
    pdf_config: pdf_config
}

const initialValues: IConfigValues = {
    email_config: {
        username: '',
        password: '',
        host: '',
        port: '',
        email_from: ''
    },
    paddle_config: {
        paddle_url: '',
        paddle_api_key: ''
    },
    paddle_client_config: {
        client_key: ''
    },
    pdf_config: {
        watermark: "",
        daily_limit: 3,
        maximum_pages_allowed:0,
    }
};

interface IPaddleEnviroment {
    enviroment: string;
    token: string;
}

class SettingsState {
    configSettings: IConfigValues = initialValues;
    clientSettings: any;


    constructor() {
        makeAutoObservable(this);
        this.fetchClientSettings();
    }

    get paddleEnviroment() {

        return {
            environment: 'sandbox',
            token: this.clientSettings?.paddleClientConfigData?.value?.client_key,
        }
    }

    private async fetchClientSettings() {
        const response = await apiClient.get("client-settings");
        console.log(response);
        if (response && response.data && response.status) {
            this.clientSettings = response.data;
        }
    }
    async getClientSettings() {
        if (!this.clientSettings) {
            await this.fetchClientSettings();
        }
        return this.clientSettings;
    }

    async getSettingsAdmin() {
        try {
            const response = await apiClient.get("admin/settings");
            if (response.status && response.data) {
                const transformedData: IConfigValues = {
                    email_config: {
                        username: response.data?.emailConfigData?.value?.username ?? '',
                        password: response.data?.emailConfigData?.value?.password ?? '',
                        host: response.data?.emailConfigData?.value?.host ?? '',
                        port: response.data?.emailConfigData?.value?.port ?? '',
                        email_from: response.data?.emailConfigData?.value?.email_from ?? '',
                    },
                    paddle_config: {
                        paddle_url: response.data?.paddleConfigData?.value?.paddle_url ?? '',
                        paddle_api_key: response.data?.paddleConfigData?.value?.paddle_api_key ?? '',
                    },
                    paddle_client_config: {
                        client_key: response.data?.paddleClientConfigData?.value?.client_key ?? '',
                    },
                    pdf_config: {
                        watermark: response.data?.pdfData?.value?.watermark ?? '',
                        daily_limit: response.data?.pdfData?.value?.daily_limit ?? 0,
                        maximum_pages_allowed:response.data?.pdfData?.value?.maximum_pages_allowed ?? 0,
                    }

                };

                this.configSettings = transformedData;
            }
        } catch (e) {
            this.handleError(e);
        }
    }

    async save(values: IConfigValues): Promise<void> {
        try {
            const response = await apiClient.post("admin/settings-store", values);
            if (response.status) {
                notificationState.addToasterSuccess(response.message || 'Settings Saved Successfully.');
            } else {
                this.handleError(response.message || "Something went wrong...");
            }
        } catch (e) {
            this.handleError(e);
        }
    }

    handleError(error: any) {
        const e = typeof error === "string" ? error : (error.message || 'Something went wrong, try again...');
        notificationState.addToasterError(e);
    }
}

const settingsState = new SettingsState();
export default settingsState;
