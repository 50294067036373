//@ts-nocheck
import React from 'react';
import classNames from 'classnames';
import useDarkMode from '../../../hooks/useDarkMode';
import Footer from '../../../layout/Footer/Footer';

const DefaultFooter = () => {
	const { darkModeStatus } = useDarkMode();

	return (
		<Footer>
			<div className='container-fluid'>
				<div className='row p-2 align-middle'>
					<div className='col-sm footer-mobile-center footer-padding'>
						<span className='fw-light'>Copyright © 2024 - Version 1.0</span>
					</div>
					<div className='col-sm-auto footer-mobile-center mb-3 mb-md-0 mb-sm-0'>
						<a
							href='/'
							className={classNames('text-decoration-none', {
								'link-dark': !darkModeStatus,
								'link-light': darkModeStatus,
							})}>
							<small className='fw-bold'>Hashe Computer Solutions (Pvt) Ltd</small>
						</a>
					</div>
				</div>
			</div>
		</Footer>
	);
};

export default DefaultFooter;
