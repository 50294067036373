import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import auth from "../../../shared/mobx/auth-state";

const VerifyAccount = () => {
    const [searchParams] = useSearchParams();
    const email = searchParams.get("email");
    const token = searchParams.get("token");
    const [isVerified, setIsVerified] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const verifyUser = async () => {
        try {
            if (!email || !token) {
                throw new Error("Email and token are required for account verification.");
            }

            const { status } = await auth.doVerifyAccount({ email, token });
            if (status) {
                setIsVerified(true);
                setTimeout(() => {
                    navigate('/auth/login'); 
                }, 1000);
            } else {
                throw new Error("Account verification failed. Please check your email and token.");
            }
        } catch (error:any) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        verifyUser();
    }, []);

    const renderContent = () => {
        if (isLoading) {
            return <h3>Verifying Account. Please wait...</h3>;
        }

        if (error) {
            return <h3>{error}</h3>;
        }

        if (isVerified) {
            return <h3>Account verified...</h3>;
        }

        return <>Verification failed. Please try again later.</>;
    };

    return <div className="text-center">{renderContent()}</div>;
};

export default VerifyAccount;
