
//@ts-nocheck

import { FC } from "react";
import subscriptionState, { ISubsPlan } from "../../../shared/mobx/subscription-state";
import PackageCard from "./PackageCard";
import { usePaddle } from '../../../hooks/usePaddle';
import settingsState from "../../../shared/mobx/settings-state";
import { observer } from "mobx-react";

interface IProps {
    packageListData: ISubsPlan[];
}

const PackageList: FC<IProps> = ({ packageListData }) => {
    const onCheckoutComplete = async (data) => {
       setTimeout(()=> {
       // window.location.reload();
       window.location.href = "/dashboard"
       }, 2000);
      };
      const paddle = usePaddle( settingsState.paddleEnviroment, onCheckoutComplete, onCheckoutComplete);
    return (
        <div className='row scroll-margin'>
     
            {
                packageListData?.map(packageData => (
                    <div className='col-md-4' id={packageListData.id} key={packageListData.id}>
                        <PackageCard packageData={packageData} paddle={paddle} />
                    </div>
                ))
            }

        </div>
    );
}

export default observer(PackageList);