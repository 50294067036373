//@ts-nocheck
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import Card, {
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../../components/bootstrap/Card';
import Checks from '../../../components/bootstrap/forms/Checks';

import { observer } from 'mobx-react-lite';

import apiKeyState from '../../../user/mobx/apiKeyState';
import Spinner from '../../../components/bootstrap/Spinner';
import LoaderWrapper from '../../../layout/Wrapper/LoaderWrapper';
import PaginationButtons from '../../../components/PaginationButtons';
import { useParams } from 'react-router-dom';
import auth from '../../../shared/mobx/auth-state';

const KeyUsage: React.FC = () => {
    const [loading, setLoading] = useState(false);
const { userId } = useParams();
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            if (auth.isUser) {
            const { data, total_records } = await apiKeyState.getKeyUsage({page:currentPage, per_page: perPage});
            setTotalItems(total_records)
            setLoading(false);
            } else {
             const { data, total_records } = await apiKeyState.getUserKeyUsage(userId,{page:currentPage, per_page: perPage});
            setTotalItems(total_records)
            setLoading(false);
            }

        };
        fetchData();
    }, [currentPage]);
    return (
        <Card>
            <CardHeader borderSize={1}>
                <CardLabel icon='Key' iconColor='info'>
                    <CardTitle tag='div' className='h5'>
                        Key Usage
                    </CardTitle>
                </CardLabel>
                {/* <CardActions>
                <Button
                    color='primary'
                    onClick={handleKeyCreateModal}>
                    Create Key
                </Button>
            </CardActions> */}
            </CardHeader>
            <CardBody className='table-responsive'>
                <LoaderWrapper isLoading={loading} data={[true]}>
                    <table className='table table-modern'>
                        <thead>
                            <tr>
                                <th>Source</th>
                                <th>Url </th>
                                <th>created At</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
{
    apiKeyState.usageList && apiKeyState.usageList.length > 0 ? (
        apiKeyState.usageList.map((keyUsage) => (
            <tr key={keyUsage.id} className='h-20'>
                <td>{keyUsage.source}</td>
                <td>{keyUsage.url}</td>
                <td>{dayjs(keyUsage.createdAt).format('MM-DD-YYYY')}</td>
                <td>
                    {
                        keyUsage.loading ? <Spinner size="12px" color="primary" /> : 
                        <Checks
                            id="status"
                            name='usageStatus'
                            className="status"
                            type="switch"
                            label={keyUsage.status}
                            onChange={() => apiKeyState.toggleUsageSourceStatus(keyUsage.id)}
                            checked={keyUsage.status === "Active"}
                        />
                    }
                </td>
            </tr>
        ))
    ) : (
        <tr>
            <td colSpan="4" className="text-center">No record found</td>
        </tr>
    )
}

                        </tbody>
                    </table>

                </LoaderWrapper>
            </CardBody>
            <PaginationButtons
                label='items'
                setCurrentPage={setCurrentPage}
                totalItems={totalItems}
                setPerPage={setPerPage}
                currentPage={currentPage}
            />
        </Card>
    );
};

export default observer(KeyUsage);
