import React from 'react';

const CookiePolicy = () => {
  return (
    <div className="container policy-wrap">
      <h2>Cookie Policy</h2>
      <p><strong>Effective Date:</strong> July 12, 2024</p>

      <h2>Introduction</h2>
      <p>Webs2PDF ("we," "our," or "us") uses cookies on our website <a href="https://app.webs2pdf.com/">https://app.webs2pdf.com/</a> (the "Service"). By using the Service, you consent to the use of cookies.</p>

      <h2>What Are Cookies?</h2>
      <p>
        Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file is stored in your web browser and allows the Service or a third party to recognize you and make your next visit easier and the
        Service more useful to you.
      </p>

      <h2>How Webs2PDF Uses Cookies</h2>
      <p>When you use and access the Service, we may place several cookie files in your web browser. We use cookies for the following purposes:</p>
      <ul>
        <li>To enable certain functions of the Service</li>
        <li>To provide analytics</li>
        <li>To store your preferences</li>
        <li>To enable advertisements delivery, including behavioral advertising</li>
      </ul>
      <p>We use both session and persistent cookies on the Service and we use different types of cookies to run the Service:</p>

      <h2>Essential Cookies</h2>
      <p>We may use essential cookies to authenticate users and prevent fraudulent use of user accounts.</p>

      <h2>Performance and Functionality Cookies</h2>
      <p>These cookies are used to enhance the performance and functionality of our Service but are non-essential to their use. However, without these cookies, certain functionality may become unavailable.</p>

      <h2>Analytics and Customization Cookies</h2>
      <p>These cookies collect information that is used either in aggregate form to help us understand how our Service is being used or how effective our marketing campaigns are, or to help us customize our Service for you.</p>

      <h2>Advertising Cookies</h2>
      <p>
        These cookies are used to make advertising messages more relevant to you. They perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed, and in some cases selecting
        advertisements that are based on your interests.
      </p>

      <h2>Social Media Cookies</h2>
      <p>These cookies are used to allow you to share pages and content that you find interesting on our Service through third-party social networking and other websites. These cookies may also be used for advertising purposes.</p>

      <h2>Third-Party Cookies</h2>
      <p>In addition to our own cookies, we may also use various third-party cookies to report usage statistics of the Service, deliver advertisements on and through the Service, and so on.</p>

      <h2>Your Choices Regarding Cookies</h2>
      <p>
        If you prefer to avoid the use of cookies on the Service, first, you must disable the use of cookies in your browser and then delete the cookies saved in your browser associated with this website. You may use this option for
        preventing the use of cookies at any time.
      </p>
      <p>If you do not accept our cookies, you may experience some inconvenience in your use of the Service, and some features may not function properly.</p>

      <h2>Managing Cookies in Your Browser</h2>
      <p>Most browsers allow you to manage your cookie settings. You can set your browser to refuse cookies or delete certain cookies. You should check your browser’s help page for more information on how to manage cookies.</p>

      <h2>Changes to This Cookie Policy</h2>
      <p>We may update our Cookie Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Please review this Cookie Policy periodically for any changes.</p>

      <h2>Contact Us</h2>
      <p>If you have any questions about our use of cookies or other technologies, please email us at <a href="mailto:info@webs2pdf.com">info@webs2pdf.com</a>.</p>
    </div>
  );
};

export default CookiePolicy;
