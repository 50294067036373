import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import CommonHeaderChat from './CommonHeaderChat';
import useDarkMode from '../../../hooks/useDarkMode';
import auth from '../../../shared/mobx/auth-state';
import { observer } from 'mobx-react';
import subscriptionState from '../../../shared/mobx/subscription-state';

const DashboardHeader = () => {
	const { darkModeStatus } = useDarkMode();
	const [remainigTrail, setRemainingTrail] = useState(0);
	useEffect(() => {
		
		let daysRemaining = 0;
		if (subscriptionState.hasSubscription && subscriptionState._userSubscription?.status === "trialing") {
			const trialEndDate = new Date(subscriptionState._userSubscription?.expiryDate);
			const currentDate = new Date();
			const timeDifference = trialEndDate.getTime() - currentDate.getTime();
			daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
			setRemainingTrail(daysRemaining > 0 ? daysRemaining : 0);
		}

	},[subscriptionState._userSubscription])
	return (
		<Header>
			<HeaderLeft>
				<div className='d-flex align-items-center'>
					<div className='row g-4'>
						<div className='col-md-auto'>
							<div
								className={classNames('fs-3', 'fw-bold', {
									'text-dark': !darkModeStatus && !(remainigTrail > 0 && remainigTrail < 3),
									'text-danger': remainigTrail > 0 && remainigTrail < 3
								})}>
								{
									remainigTrail > 0 ? (
										<>{ remainigTrail } Trail Days Remaining</>
									) : <>Hi,{auth.currentUser?.firstName}!</>
								}
								
							</div>
						</div>
					</div>
				</div>
			</HeaderLeft>
			<HeaderRight>
				<CommonHeaderChat />
			</HeaderRight>
		</Header>
	);
};

export default observer(DashboardHeader);
