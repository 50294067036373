//@ts-nocheck
// src/components/AddUserModal.tsx
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Modal from '../../../components/bootstrap/Modal';
import Button from '../../../components/bootstrap/Button';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Card, {
  CardActions,
  CardBody,
  CardFooter,
  CardHeader,
  CardLabel,
  CardSubTitle,
  CardTitle,
} from '../../../components/bootstrap/Card';
import userState from '../../../shared/mobx/user-state';

const baseSchema = {
  firstName: Yup.string()
  .min(3, 'First Name must be at least 3 characters')
  .required('First Name is required'),

lastName: Yup.string()
  .min(3, 'Last Name must be at least 3 characters')
  .required('Last Name is required'),

  email: Yup.string().email('Invalid email format').required('Email is required'),
};

// Password validation rules

const passwordRules = {
  password: Yup.string()
    .min(3, 'Password must be between 3 to 30 characters long')
    .max(30, 'Password must be between 3 to 30 characters long')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
};

// Function to create the validation schema based on the presence of user.id
const createValidationSchema = (user = {}) => {
  let schemaFields = { ...baseSchema };

  if (!user.id) {
    // For new users, password fields are required
    schemaFields = { ...schemaFields, ...passwordRules };
  } else {
    // For existing users, password fields are optional, but if provided, must match
    schemaFields.password = Yup.string();
    schemaFields.confirmPassword = Yup.string()
      .when("password", {
        is: val => val && val.length > 0, // only apply if password is not empty
        then: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
      });
  }

  return Yup.object(schemaFields);
};
const AddUserModal = ({ isOpen, onClose, refresher, user={} }) => {
    const [isLoading, setLoading]=useState(false);
    const validationSchema = createValidationSchema(user);
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      ...user,
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      let isSuccess = false;
        if(user.id) {
          isSuccess = await userState.updateUser({...values,id:user.id});
        } else {
          isSuccess = await userState.createUser(values);
      }
            setLoading(false);
      if (isSuccess) {
        refresher();
        onClose();
      }
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={onClose} // Assuming your Modal component can handle this prop for closing itself
      titleId="Add New User"
      isStaticBackdrop={true}
      isCentered={true}
      isAnimation={true}
      title="Add New User"
      onClose={onClose} // Assuming your Modal component uses this prop as a callback to close the modal
    >
      <>
        <CardHeader>
          <CardLabel icon='Person' iconColor='success'>
            <CardTitle tag='div' className='h5'>
              Add New User
            </CardTitle>
            <CardSubTitle tag='div' className='h6'>
              User's Information
            </CardSubTitle>
          </CardLabel>
        </CardHeader>
        <CardBody>
          <form onSubmit={formik.handleSubmit} noValidate>
            {/* Form fields here, using Formik's handleChange and handleBlur */}
            {/* First Name, Last Name, Display Name, Email, Password, Confirm Password */}
            <div className='row g-4'>
              {/* First Name */}
              <FormGroup id='firstName' label='First Name' isFloating>
                <Input
                  name='firstName'
                  placeholder='First Name'
                  autoComplete='given-name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                />
                {formik.touched.firstName && formik.errors.firstName && <div className="text-danger">{formik.errors.firstName}</div>}
              </FormGroup>

              {/* Last Name */}
              <FormGroup id='lastName' label='Last Name' isFloating>
                <Input
                  name='lastName'
                  placeholder='Last Name'
                  autoComplete='family-name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                />
                {formik.touched.lastName && formik.errors.lastName && <div className="text-danger">{formik.errors.lastName}</div>}
              </FormGroup>


              {/* Email */}
              <FormGroup id='email' label='Email' isFloating>
                <Input
                  name='email'
                  type='email'
                  disabled={!!user.id}
                  placeholder='Email'
                  autoComplete='email'
                  onChange={formik.handleChange}
                  onBlur={formik.handle}
                    value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email && <div className="text-danger">{formik.errors.email}</div>}
                  </FormGroup>
    
                  {
                    !user.id && (<>
                    {/* Password */}
                  <FormGroup id='password' label='Password' isFloating>
                    <Input
                      name='password'
                      type='password'
                      placeholder='Password'
                      autoComplete='new-password'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />
                    {formik.touched.password && formik.errors.password && <div className="text-danger">{formik.errors.password}</div>}
                  </FormGroup>
    
                  {/* Confirm Password */}
                  <FormGroup id='confirmPassword' label='Confirm Password' isFloating>
                    <Input
                      name='confirmPassword'
                      type='password'
                      placeholder='Confirm Password'
                      autoComplete='new-password'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.confirmPassword}
                    />
                    {formik.touched.confirmPassword && formik.errors.confirmPassword && <div className="text-danger">{formik.errors.confirmPassword}</div>}
                  </FormGroup>
                    </>)
                  }
    
                  {/* Submit Button */}
                  <CardActions className='text-end'>
                  <Button 
                    
                  type="button" isDisable={isLoading}  color="dangar" onClick={()=>onClose(false)}>
                      Cancel
                    </Button>
                    <Button isDisable={isLoading} type="submit" color="primary">
                            {  !isLoading ? ( 
                               user?.id ? ( <> Update </>) : (<>Submit</>)
                            ) : <>Please wait...</>}
                    </Button>
        
                  </CardActions>
                </div>
          </form>
        </CardBody>
         </>
        </Modal>
      );
    };
    
    export default AddUserModal;
    