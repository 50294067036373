// @ts-nocheck
import { saveAs } from 'file-saver';
import notificationState from '../mobx/notification-state';
import { BASE_URL } from '../constants/globalContants';
export const DownloadFile = async (pdf_url, token) => {
  try {
    const apiUrl = `${BASE_URL}downloadpdf`;
    const requestData = {pdf_url, token };

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    });

    if (!response.ok) {
      throw new Error('Failed to download PDF');
    }

    const blob = await response.blob();
    const name = pdf_url.split("/")
    saveAs(blob, name[name.length - 1]);
  } catch (error) {
    notificationState.addToasterError('File download failed');
  }
};


