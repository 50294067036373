// @ts-nocheck
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import './i18n';
import Login from './pages/presentation/auth/Login';
import Index from './user';
import Portal from './layout/Portal/Portal';
import NotificationToast from './user/components/notification/NotificationToast';
import { ReactNotifications } from 'react-notifications-component';
import { ToastContainer } from 'react-toastify';
import { ToastCloseButton } from './components/bootstrap/Toasts';
import Signup from './pages/presentation/auth/Signup';
import ForgotPassword from './pages/presentation/auth/ForgotPassword';
import ResetPassword from './pages/presentation/auth/ResetPassword';
import VerifyAccount from './pages/presentation/auth/VerifyAccount';
import VerifyEmail from './pages/presentation/auth/VerifyEmail';
import PrivacyPolicyPage from './user/PrivacyPolicyPage';
import CookiePolicyPage from './user/CookiePolicyPage';
import TermsOfUsePage from './user/TermsOfUsePage';


const Root = () => {
  return (
    <ThemeContextProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path='/privacy-policy' element={<PrivacyPolicyPage/>} />
          <Route path='/cookie-policy' element={<CookiePolicyPage/>} />
          <Route path='/terms-of-use' element={<TermsOfUsePage/>} />
          <Route path="/auth/login" element={<Login />} />
		      <Route path="/auth/sign-up" element={<Signup/>} />
          <Route path="/auth/forgot-password" element={<ForgotPassword/>}/>
          <Route path="/auth/resend-verification-email" element={<VerifyEmail/>}/>
          <Route path="/reset_password" element={<ResetPassword/>}/>
          <Route path="/verify_account" element={<VerifyAccount/>}/>
    
          {/* <Route path="*" element={<App />} />  */}
          <Route path="/*"   element={<App/>} />

        
        </Routes>
      </Router>
      <Portal id='portal-notification'>
					<ReactNotifications />
				</Portal>
				<ToastContainer closeButton={ToastCloseButton} toastClassName='toast show' />
				<NotificationToast/>
    </ThemeContextProvider>
  );
};

const container = document.getElementById('root');
createRoot(container as Element).render(<Root />);

reportWebVitals();
