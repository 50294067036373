import React, { FC, ReactNode } from 'react';

import PropTypes from 'prop-types';

import Spinner from '../../components/bootstrap/Spinner';

interface IWrapperContainerProps {
	children: ReactNode;
    isLoading:boolean;
    data: any[] | undefined | null,
}

export const LoaderWrapper: FC<IWrapperContainerProps> = ({ children, isLoading, data=[], ...props }) => {
    if(isLoading) {
        return (
            <div className='text-center font-weight-bold p-4'>Loading, Please wait...  <Spinner size="14px" color="primary" {...props} /></div>
        )
    }
    if (!data || !data.length) {
       return  <div className='text-center font-weight-bold p-4'>No Data Found</div>
    }
	return <>{children}</>;
};

LoaderWrapper.propTypes = {
	children: PropTypes.node.isRequired,
    isLoading:PropTypes.bool.isRequired,
};

export default LoaderWrapper;
   