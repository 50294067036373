import React from 'react';

import footerLogo from '../images/f-logo.png';


const Footer: React.FC = () => {
  return (
    <footer>
      <div className="container">
        <img src={footerLogo} alt="Footer Logo" />
        <div className="copyright-wrap">
          <p>Copyright © 2024 www.web2pdf.com  All rights reserved.</p>
          <ul>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
            <li><a href="/cookie-policy">Cookie Policy</a></li>
            <li><a href="/terms-of-use">Terms of Use</a></li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
