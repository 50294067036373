interface IValues {
	firstName?: string;
	lastName?: string;
	emailAddress?: string;
	currentPassword?: string;
	newPassword?: string;
	confirmPassword?: string;
}
const validate = (values: IValues, passwordChangeCTA:boolean) => {
	const errors: IValues = {};
	if (passwordChangeCTA) {
		if (!values.currentPassword) {
			errors.currentPassword = 'Please provide a current password.';
		}
			if (!values.newPassword) {
				errors.newPassword = 'Please provide a valid password.';
			} else {
				if (!(values.newPassword.length >= 3 && values.newPassword.length <= 30)) {
					errors.newPassword =
						'Password must be between 3 and 30 characters long';
				}
			
			}
	
			if (!values.confirmPassword) {
				errors.confirmPassword = 'Please provide a valid password.';
			} else if (values.newPassword !== values.confirmPassword) {
				errors.confirmPassword = 'Passwords do not match.';
			}
		
	} else {
		if (!values.firstName) {
			errors.firstName = 'Required';
		} else if (values.firstName.length < 3) {
			errors.firstName = 'Must be 3 characters or more';
		} else if (values.firstName.length > 20) {
			errors.firstName = 'Must be 20 characters or less';
		}
	
		if (!values.lastName) {
			errors.lastName = 'Required';
		} else if (values.lastName.length < 3) {
			errors.lastName = 'Must be 3 characters or more';
		} else if (values.lastName.length > 20) {
			errors.lastName = 'Must be 20 characters or less';
		}
	
	}
	// if (!values.displayName) {
	// 	errors.displayName = 'Required';
	// } else if (values.displayName.length > 30) {
	// 	errors.displayName = 'Must be 20 characters or less';
	// }

	// if (!values.emailAddress) {
	// 	errors.emailAddress = 'Required';
	// } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailAddress)) {
	// 	errors.emailAddress = 'Invalid email address';
	// }


	console.log(errors);
	return errors;
};

export default validate;
