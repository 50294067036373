import React from 'react';

import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
// import SubHeader, {
// 	SubHeaderLeft,
// 	SubHeaderRight,
// 	SubheaderSeparator,
// } from '../../../layout/SubHeader/SubHeader';
import Page from '../../../layout/Page/Page';
import Popovers from '../../../components/bootstrap/Popovers';
import PaddlePay from '../../../components/payment/PaddlePay';
import AdminDashboard from './AdminDashboard';
import auth from '../../../shared/mobx/auth-state';
import UserDashboard from './UserDashboard';

const DashboardPage = () => {
	return (
		<PageWrapper title='Dashboard'>
			{/* <SubHeader>
				<SubHeaderLeft>
					<Popovers
						title='DashboardPage.tsx'
						desc>
						SubHeaderLeft
					</Popovers>
				
					<SubheaderSeparator />
				</SubHeaderLeft>
				<SubHeaderRight>
					<Popovers
						title='DashboardPage.tsx'
						desc={<code>src/pages/presentation/dashboard/DashboardPage.tsx</code>}>
						SubHeaderRight
					</Popovers>
					<code>DashboardPage.tsx</code>
				</SubHeaderRight>
			</SubHeader> */}
			<Page>
				{
					auth.isAdmin ? <AdminDashboard/> : <UserDashboard/>
				}
			</Page>
		</PageWrapper>
	);
};

export default DashboardPage;
