/* eslint-disable eqeqeq */
// @ts-nocheck
import { configure, makeAutoObservable } from "mobx";
import { apiClient } from "../base-classes/apiBaseClass";
import notificationState from "./notification-state";
import pdfServiceStore from "../../user/mobx/pdfState";
// import { redirect } from "react-router-dom";
enum UserRoles {
    Admin = "admin",
    User = "user",
}

enum LocalStorageKeys {
    User = "web2pdf-user",
}

export interface IUser {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    activationToken: string;
    emailVerifiedAt: string | null;
    lastLogin: string | null;
    createdAt?: string;
    updatedAt?: string;
    deleted?: boolean;
    status?: string;
    authToken: string;
    role: string;
    address_id?: string;
    customer_id?: string;
    business_id?: string;
    profileImage?:string;
}


class Auth {
    private _user?: IUser | null | undefined;

    constructor() {
        this.synchronizeUserState();
        configure({ enforceActions: "never" });
        makeAutoObservable(this);
    }

    /* ============= Computed Properties ============= */
    get currentUser(): IUser | null | undefined { return this._user; }

    get activeRole(): string {
        return this.isLoggedIn()
            ? this.currentUser!.role : "log-out";
    }

    get isUser(): boolean {
        return this.activeRole === UserRoles.User;
    }

    get isAdmin(): boolean {
        return this.activeRole === UserRoles.Admin;
    }

    get isGuest(): boolean {
        return !this.isLoggedIn();
    }

    /* ============= Public Functions ============= */
    isLoggedIn(): boolean {
        return !!this.currentUser;
    }

    async doLogin(values) {
        pdfServiceStore.resetState();
        let status = 0, error = "";
        try {
            const response = await apiClient.post("login", values);
            console.log(response.message);
            if ((response?.errors && response.errors[0]) || response.status == 0) {
                error = response?.errors && response.errors[0] ?
                    response?.errors[0] : response.message;
                notificationState.addToasterError(error);
            } else {
                notificationState.addToasterSuccess("Login Successfully");
                this.setUser({
                    ...response.data.user,
                    authToken: response.data.token,
                });
                status = 1;
            }
        } catch (e: any) {
            error = e.message || "Error..."
            notificationState.addToasterError(error);
        }
        return { status, error };
    }

    // pass useNavigation function to this...
    async navigateToRole(navigateFn: any, returnUrl?: string) {
        const navAppRole = {
            user: "/",
            admin: "/dashboard",
        }
        await navigateFn(returnUrl ? returnUrl : (navAppRole[this.activeRole] ?? "/auth/login"));
    }

    async doSignUp(values) {
        pdfServiceStore.resetState();
        let status = 0, error = "";
        try {
            const response = await apiClient.post("register",
                { ...values, agreeToTerms: undefined, confirmPassword: undefined });
            
            if ((response?.errors && response.errors[0]) || response.status == 0) {
                  status = 0;
                // this.setUser(response.data);
                error = response?.errors && response.errors[0] ?
                    response?.errors[0] : response.message;
                notificationState.addToasterError(error);
            } else {
                status = 1;
                notificationState.addToasterSuccess(response?.message ||
                    "Registration has been completed...");
                // this.setUser(response.data);
            }
        } catch (e: any) {
            error = e.message || "Error..."
        }
        return { status, error };
    }

    async doVerifyAccount(data) {
        let status = 0, error = "";
        try {
            const response = await apiClient.post("verifyemail", data);
            status = response.status;
            if (response.errors && response.errors[0]) {
                notificationState.addToasterError(response.errors[0]);
            } else {
                notificationState.addToasterSuccess(response?.message ||
                    "Account has been verified...");
                status = 1;
            }
        } catch (e: any) {
            error = e.message || "Error..."
            notificationState.addToasterError(error);
        }
        return { status, error };
    }

    async doVerifyEmail(data) {
        let status = 0, error = "";
        try {
            const response = await apiClient.post("reset-email-link", data);
            status = response.status;
            if (response.errors && response.errors[0]) {
                notificationState.addToasterError(response.errors[0]);
            } else {
                notificationState.addToasterSuccess(response?.message || "Success...");
                status = 1;
            }
        } catch (e: any) {
            error = e.message || "Error..."
            notificationState.addToasterError(error);
        }
        return { status, error };
    }

    async doForgotPassword(data) {
        let status = 0, error = "";
        try {
            const response = await apiClient.post("forget-password", data);
            status = response.status;
            if (response.errors && response.errors[0]) {
                notificationState.addToasterError(response.errors[0]);
            } else {
                notificationState.addToasterSuccess(response?.message || "Success...");
                status = 1;
            }
        } catch (e: any) {
            error = e.message || "Error..."
            notificationState.addToasterError(error);
        }
        return { status, error };
    }

    async resetPassword(token, values) {
        let status = 0, error = "";
        try {
            const response = await apiClient.post("reset-password",
                { token, password: values.password });
            status = response.status;
            if (response.errors && response.errors[0]) {
                notificationState.addToasterError(response.errors[0]);
            } else {
                notificationState.addToasterSuccess(response?.message || "Success...");
                status = 1;
            }
        } catch (e: any) {
            error = e.message || "Error..."
            notificationState.addToasterError(error);
        }
        return { status, error };
    }

    doLogout(): void {
        window.location.href = '/auth/login?preaction=logout';
    }
    logout(): void {
        pdfServiceStore.resetState();
        this.clearUserState();
        notificationState.addToasterSuccess("Logout Successfully");
    }

    clearUserState() {
        try {
            localStorage.removeItem(LocalStorageKeys.User);
            this._user = null;
        } catch (error) {
            console.error("Error: Unable to clear user state", error);
        }
    }

    getAllRoles(): typeof UserRoles {
        return UserRoles;
    }


    /* ============= Private Functions ============= */
    private setUser(user: IUser): void {
        this._user = user;
        this.synchronizeUserState();
    }

    public synchronizeUserState(): void {
        try {
            if (this.currentUser) {
                const userJson = JSON.stringify(this.currentUser);
                localStorage.setItem(LocalStorageKeys.User, userJson);
            }
        } catch (error) {
            console.error("Error: Unable to set user in local storage", error);
        }

        try {
            const storedUser = localStorage.getItem(LocalStorageKeys.User);
            if (storedUser) this._user = JSON.parse(storedUser);
        } catch (error) {
            console.error("Error: No user found in local storage", error);
        }
    }

    /* ========= API Request functions ============= */
}

const auth = new Auth();

export default auth;
