//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { useNavigate } from 'react-router-dom';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import Page from '../../../layout/Page/Page';
import { demoPagesMenu } from '../../../menu';
import data from '../../../common/data/dummyCustomerData';
import Button from '../../../components/bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Avatar from '../../../components/Avatar';
import Icon from '../../../components/icon/Icon';
import { priceFormat, toTitleCase } from '../../../helpers/helpers';
import latestSalesData from '../../../common/data/dummySalesData';
import useSortableData from '../../../hooks/useSortableData';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
} from '../../../components/PaginationButtons';

import { getColorNameWithIndex } from '../../../common/data/enumColors';
import useDarkMode from '../../../hooks/useDarkMode';
import LoaderWrapper from '../../../layout/Wrapper/LoaderWrapper';
import subscriptionState from '../../../shared/mobx/subscription-state';
import userState from '../../../shared/mobx/user-state';
import ConfirmationModal from '../ConfirmationModal';
const currencyMapper = {
	"USD": "$"
};
const EVENT_STATUS = {
	'trailing': "primary",
	'active': "success",
	'update': "info",
	'cancel': "danger"
};
const Customer = () => {

	const { id } = useParams();
	const [data, setData] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [user, setUser] = useState({});
	const [subscription, setUserSubscription] = useState({});
	const navigate = useNavigate();
	const [perPage, setPerPage] = useState(4);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalItems, setTotalItems] = useState(0);
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [updateCount, setUpdateCount] = useState(0)
	const handleBack = () => {
		navigate(-1);
	};
	const HandleCancel = async () => {
		setLoading(true);
		try {
			const status = await subscriptionState.cancelUserSubscription({
				user_id: +id,
				subId: subscription.subscription_id,
				plan_id: subscription.plan_id,
				transaction_id: subscription.transaction_id
			});
			if (status) {
				setUpdateCount(updateCount + 1);
			}
		} finally {
			setLoading(false);
			setConfirmationModal(false)
		}

	}
	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const subscription_ = await subscriptionState.adminUserSubscription(id);
			setUserSubscription(subscription_)
			console.log(subscription_);
			const user_ = await userState.getSingleUserDetail(id);
			//const data_ = await subscriptionState.userSubscriptionHistory(id);
			const { data: data_, total_records } = await subscriptionState.userSubscriptionHistory(id, { page: currentPage, per_page: perPage });
			setTotalItems(total_records)

			setLoading(false);
			setData(data_);
			setUser(user_);
		};
		fetchData();
	}, [currentPage, updateCount]);

	return (
		<PageWrapper title="User Detail">
			<SubHeader>
				<SubHeaderLeft>
					<Button
						color='primary'
						isLink
						icon='ArrowBack'
						onClick={handleBack}
					>
						Back to List
					</Button>
				</SubHeaderLeft>
			</SubHeader>
			<Page className='mt-2'>
				<ConfirmationModal
					isOpen={confirmationModal} // Use the renamed state variable
					setIsOpen={setConfirmationModal} // Use the updated setter function
					loading={loading}
					title="Confirmation"
					message="Are you sure you want to cancel?"
					handleAction={HandleCancel}
				/>
				<div className='row'>
					{/* <div className='col-12'>
						<div className='display-6 fw-bold py-2'>User Detail</div>
					</div> */}
					<div className='col-md-4'>
						<Card>
							<CardHeader className='text-center mt-3'>
								<CardLabel iconColor='info'>
									<CardTitle tag='div' className='h5 text-center'>
										Current Subscription
									</CardTitle>
								</CardLabel>
							</CardHeader>
							{subscription  && subscription.id? (
								<>
									<CardBody className='pt-3'>
										<div className='row g-3'>

											<div className='col-12'>
												<div className='col py-1 pt-0'>
													<h4 className='fw-bold display-5'>{subscription.package_name}</h4>
												</div>
												<h4 className='display-5 fw-bold'>
													<span className='display-4 fw-bold'>{currencyMapper[subscription.currency_code] ?? "$"}</span>{subscription.package_price}
													<span className='display-6'>/  {subscription.billing_cycle}</span>
												</h4>
												<table className='w-100'>
													<tbody>
														<tr>

															<td >Renewal Date -	{dayjs(subscription.expiryDate).format('MM-DD-YYYY')}</td>
														</tr>
														<tr>

															<td > Activation Date -	{dayjs(subscription.activationDate).format('MM-DD-YYYY')}</td>
														</tr>
														<tr>

															<td >Payment Date -	{dayjs(subscription.paid_at).format('MM-DD-YYYY')}</td>
														</tr>
														{/* <tr>
															<td className='font-weight-bold'><strong>Trail End</strong></td>
															<td className='text-end'>	{dayjs(subscription.trialEndDate).format('MM-DD-YYYY')}</td>
														</tr> */}
													</tbody>
												</table>


											</div>
										</div>
									</CardBody>
									<CardFooter>
										<Button
											onClick={() => setConfirmationModal(true)}
											color='danger' isLight size='lg' className='w-100'>
											Cancel Plan
										</Button>
									</CardFooter>
								</>
							) : (

								<CardBody>
									<div className='row g-3'>
										<div className='col-12'>
											<div className='col text-center py-4'>
												<h4 className='fw-bold'>
													No Active Subscription
												</h4>
											</div>
										</div>
									</div>
								</CardBody>
							)}
						</Card>
					</div>
					
					<div className='col-sm'>

						<Card>
														<CardHeader borderSize={1}>
								<CardLabel iconColor='info'>
									<CardTitle tag='div' className='h5'>
										User Subscription History
									</CardTitle>
								</CardLabel>
								{/* Export button can be added here if needed */}
							</CardHeader>
							<LoaderWrapper isLoading={loading} data={data}>
								<table className='table table-modern'>
									<thead>
										<tr>
											{/* <td aria-labelledby='Image' style={{ width: 60 }} /> */}
											<th>Name</th>
											<th>Created at</th>
											<th>Trail End Date</th>
											<th>Renewal Date</th>
											<th style={{ width: '150px', paddingLeft: '30px' }}>Status</th>
											{/* <th className='text-center' style={{ width: '150px' }}>Action</th> */}

											{/* <td aria-labelledby='Actions' /> */}
										</tr>
									</thead>
									<tbody>
										{data?.map((item) => (
											<tr key={item.id}>
												{/* <td>
													<Button
														isLight
														color={item.status.color}
														icon='User'
														onClick={handleUpcomingDetails}
														aria-label='More info'
													/>
												</td> */}
												<td>
													<div className='d-flex'>
														{/* <div className='flex-shrink-0'>
															<img
																srcSet={item.assigned.srcSet}
																src={item.assigned.src}
																alt={item.assigned.name}
																width='36'
																height='36'
																className={classNames(
																	`bg-l${
																		false ? 'o' : ''
																	}25-${item.assigned.color}`,
																	'rounded-circle',
																)}
															/>
														</div> */}
														<div className='flex-grow-1  d-flex align-items-center'>
															{item.package_name}
														</div>
													</div>
												</td>
												<td>
													<div className='d-flex'>
														<div className='flex-grow-1 d-flex align-items-center'>
															{dayjs(item.createdAt).format('MM-DD-YYYY')}
														</div>
													</div>
												</td>

												<td>
													<div className='d-flex'>
														<div className='flex-grow-1  d-flex align-items-center'>
															{dayjs(item.trialEndDate).format('MM-DD-YYYY')}
														</div>
													</div>
												</td>
												<td>
													<div className='d-flex'>
														<div className='flex-grow-1  d-flex align-items-center'>
															{dayjs(item.expiryDate).format('MM-DD-YYYY')}
														</div>
													</div>
												</td>
												<td>
													<span className='bad'>
														<Button
															isLink
															hoverShadow='none'
															isDisable={true}
															color={EVENT_STATUS[item.status] ?? 'warning'}
															icon='Circle'
															className='text-nowrap'>
															{toTitleCase(item.status)}
														</Button>
													</span>
												</td>
												{/* <td className='text-center'>
													{
														subscription && subscription.subscription_id === item.subscription_id && ['trialing','active'].includes(item.status) && (
															<Button
																onClick={() => setConfirmationModal(true)}
																color='danger' isLight size='sm'>
																Cancel Plan
															</Button>
														)
													}
												</td> */}

											</tr>
										))}
									</tbody>
								</table>
								<PaginationButtons
									label='items'
									setCurrentPage={setCurrentPage}
									totalItems={totalItems}
									setPerPage={setPerPage}
									currentPage={currentPage}
								/>
							</LoaderWrapper>
						</Card>

					</div>
				</div>
			</Page>

		</PageWrapper>
	);
};

export default Customer;
