// @ts-nocheck
import { makeAutoObservable, reaction, configure } from "mobx";

enum NotificationType {
    Error   = 'error',
    Info    = 'info',
    Success = 'success',
    Warning = 'warning'
}

type INotification = {
    id: string;
    type: NotificationType;
    message: string;
    isDisplayed: boolean;
}

class NotificationState {
    notificationArray: INotification[] = [];

    get activeNotifictions() {
        return this.notificationArray.filter(n => !n.isDisplayed) 
    }

    constructor() {
        configure({ enforceActions: "never" });
        makeAutoObservable(this);
        reaction(
            () => this.notificationArray.filter(n => n.isDisplayed),
            (displayedNotifications) => {
                displayedNotifications.forEach((notification) => {
                    this.removeNotification(notification.id);
                });
            }
        );  
    }

    addToaster(type: NotificationType, message: string) {
        const id = this.generateUniqueId();
        this.notificationArray.push({ id, type, message, isDisplayed:false });
    }

    addToasterError(message: string) {
        this.addToaster(NotificationType.Error, message);
    }

    addToasterSuccess(message: string) {
        this.addToaster(NotificationType.Success, message);
    }

    addToasterInfo(message: string) {
        this.addToaster(NotificationType.Info, message);
    }

    addToasterWarning(message: string) {
        this.addToaster(NotificationType.Warning, message);
    }

    removeNotification(id: string) {
        this.notificationArray = this.notificationArray.filter(notification => notification.id !== id);
    }

    setNotificationDisplayed(id: string) {
        const index = this.notificationArray.findIndex((n) => n.id === id);
        if (index > -1) {
            this.notificationArray[index].isDisplayed = true;
        }
    }

    private generateUniqueId() {
        return Math.random().toString(36).substring(2, 11);
    }
}

const notificationState = new NotificationState();
export default notificationState;
