// @ts-nocheck
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { BASE_URL } from '../constants/globalContants';
import auth from '../mobx/auth-state';
import notificationState from '../mobx/notification-state';

interface IApiResponse<D = any> extends AxiosResponse<D> {
    message?: string;
    errors?: any[];
}

class ApiBase {
    public readonly instance: AxiosInstance = axios.create({
        baseURL: BASE_URL,
    });

    public constructor() {
        this._initializeRequestInterceptor();
        this._initializeResponseInterceptor();
    }

    private _initializeRequestInterceptor = () => {
        this.instance.interceptors.request.use(
            (config) => {
                config.headers = config.headers || {}; // Ensure headers object is initialized
                const authToken = auth.currentUser?.authToken;
                if (authToken) {
                    config.headers['Authorization'] = `Bearer ${authToken}`;
                }
                return config;
            },
            (error) => Promise.reject(error)
        );
    };

    private _initializeResponseInterceptor = () => {
        this.instance.interceptors.response.use(
            this._handleResponse,
            this._handleError,
        );
    };

    private _handleResponse = ({ data }: IApiResponse) => data;

    public _handleError = (error: any) => {
        if(error?.response?.status === 401) {
            notificationState.addToasterError(error?.response?.data?.message || error.message || 'Something went wrong, try again...');
            auth.doLogout();
        }
        return Promise.reject(error)
    };

    public get<T = any, R = IApiResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.instance.get(url, config);
    }

    public post<T = any, R = IApiResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
        return this.instance.post(url, data, config);
    }

    public put<T = any, R = IApiResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
        return this.instance.put(url, data, config);
    }

    public delete<T = any, R = IApiResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.instance.delete(url, config);
    }

}
export const apiClient = new ApiBase();
// export default ApiBase;

