//@ts-nocheck
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../bootstrap/Button';
// Removed import for useDarkMode since it's not used

import { observer } from 'mobx-react';
import auth from '../../shared/mobx/auth-state';
import subscriptionState from '../../shared/mobx/subscription-state';
import { Paddle } from '@paddle/paddle-js';

import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '../bootstrap/Modal'
import LoaderWrapper from '../../layout/Wrapper/LoaderWrapper';

interface IProps {
  priceId: string | number;
  packagePrice?: number | string; // Corrected to camelCase
  paddle: Paddle
}

const PaddlePay = (props: IProps) => {
  const [state, setState] = useState(false);
  const [loading, setloading] = useState(false);
  let action = "Select Plan";
  const { priceId, packagePrice, paddle } = props;
  let customer = {
    id: auth.currentUser.customer_id,
    address: {
      id: auth.currentUser.address_id,
    },
    business: auth.currentUser.business_id ? {
      id: auth.currentUser.business_id,
    } : undefined,
  };

  const isUpgrade = subscriptionState.UserActiveSubscription && +subscriptionState.UserActiveSubscription?.unit_price?.amount <= +packagePrice;
  const isDowngrade = subscriptionState.UserActiveSubscription && +subscriptionState.UserActiveSubscription?.unit_price?.amount > +packagePrice;

  if (isUpgrade) {
    action = "Upgrade";
  }
  if (isDowngrade) {
    action = "Downgrade";
  }
  if (subscriptionState.UserActiveSubscription &&
    subscriptionState.UserActiveSubscription.id === priceId) {
    action = "Active";
  }


  const handleAction = async () => {
    setloading(true);
    switch (action) {
      case "Upgrade":
      case "Downgrade":
        await subscriptionState.update(priceId);
        setState(false);
        break;
      case "Cancel":
        await subscriptionState.cancel();
        setState(false);
        break;
      default:
        await openCheckout();
        break;
    }
    setloading(false);

  }
  const openCheckout = () => {
    if (!priceId) {
      console.error('Price ID is required.');
      return;
    }
    paddle?.Checkout.open({
      items: [{ priceId: priceId.toString(), quantity: 1 }],
      customData: {
        userId: auth.currentUser!.id,
        email: auth.currentUser!.email,
      },
      customer: auth.currentUser.customer_id &&
        auth.currentUser.address_id &&
        auth.currentUser.business_id ? customer : {
        email: auth.currentUser!.email,
        name: auth.currentUser?.firstName
      }
    });
  };

  return (
    <>

      <Modal
        isOpen={state}
        setIsOpen={setState}
        titleId='confirmationModal'
        isStaticBackdrop={true}

        isCentered={true}
        isAnimation={true}>
        <ModalHeader setIsOpen={setState}>
          <ModalTitle id='exampleModalLabel'>Confirmation</ModalTitle>
        </ModalHeader>
        <LoaderWrapper isLoading={loading} data={[true]} >
          <ModalBody> Are you sure you want to {action.toLowerCase()} the plan ?</ModalBody>
          <ModalFooter>
            <Button
              color='info'
              isOutline
              className='border-0'
              onClick={() => setState(false)}>
              No
            </Button>
            <Button onClick={handleAction} color='info'>
              Yes
            </Button>
          </ModalFooter>
        </LoaderWrapper>

      </Modal>
      <Button
        onClick={() => {
          if (action === "Select Plan")
            handleAction()
          else
            setState(true)
        }}
        disabled={!paddle || action === "Active"}
        isLight
        className='w-100 py-3 text-uppercase w-100'
        size='lg'
        color='primary'>
        {action}
      </Button>
    </>

  );
};

PaddlePay.propTypes = {
  priceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  packagePrice: PropTypes.number,
};

export default observer(PaddlePay);
