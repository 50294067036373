import React, { lazy } from 'react';
import { observer } from 'mobx-react'; // Import observer from MobX React bindings
import { Route, Routes } from 'react-router-dom';
import Page404 from '../../pages/presentation/auth/Page404'; // Import Page404 directly
import auth from '../../shared/mobx/auth-state';
import { routes } from '../../routes/contentRoutes';




const ContentRoutes = observer(() => {
  return (
    <Routes>
      {routes.map((route, index) => (
        // Check if the current user's role has access to this route
        route.access.includes(auth.activeRole) ? (
          <Route
            key={index}
            path={route.path}
            element={<route.component />}
          />
        ) : null
      ))}
      <Route path='*' element={<Page404 />} />
    </Routes>
  );
});

export default ContentRoutes;
