// usePaddle.ts
// @ts-nocheck
import { useState, useEffect } from 'react';
import { initializePaddle, Paddle } from '@paddle/paddle-js';

let paddleInstance: Paddle | null = null; // This variable will persist across re-renders and component mounts

export const usePaddle = (config: { environment: string; token: string }, onCheckoutComplete?: (data: any) => void, onCheckoutClose?: () => void) => {
  const [paddle, setPaddle] = useState<Paddle | null>(null);

  useEffect(() => {
    if (!paddleInstance) { // Check if Paddle is not already initialized
      initializePaddle(
        {
          ...config,
          eventCallback(event) {
                if(onCheckoutComplete && event.name === 'checkout.completed') {
                  onCheckoutComplete(event.data);
                }
                if(onCheckoutClose &  event.name === 'checkout.closed') {
                  onCheckoutClose();
                }
        },
        }
      ).then((instance) => {
        paddleInstance = instance; // Save the initialized instance to the persistent variable
        setPaddle(instance); // Update the state to trigger re-render with the Paddle instance
      }).catch((error) => {
        console.error('Paddle initialization failed:', error);
      });
    } else {
      setPaddle(paddleInstance);
      //setupEvents(paddleInstance);  // If already initialized, just set the instance
    }
  }, [config]); // Depend on `config` to re-initialize if config changes, though typically you wouldn't change config often

  return paddle;
};
