//@ts-nocheck
import React, { useState, useEffect, useRef } from 'react';
import Spinner from '../../components/bootstrap/Spinner';
import pdfServiceStore from '../mobx/pdfState';
import { observer } from 'mobx-react';
import auth from '../../shared/mobx/auth-state';

const PdfProgress = ({ conversionType, showProgressText, notify, handleStartOver }) => {
    const [progressText, setProgressText] = useState("Loading webpage...");
    const messages = [
        {
            text: conversionType === "current_page" ? `Waiting for a webpage to load` : `Waiting for webpages to load`,
            progress: 0,
        },
        // {
        //     text: "Preparing content...",
        //     progress: 5,
        // },
        // {
        //     text: "Converting to PDF...",
        //     progress: 10,
        // },
        // {
        //     text: "Finalizing document...",
        //     progress: 80,
        // },
        // {
        //     text: "Almost there...",
        //     progress: 90,
        // },
        // {
        //     text: "Conversion Completed!",
        //     progress: 100,
        // }
    ];

    const middleMessages = [
        {
            text: "Accessing URL..."
        },
        {
            text: "Scanning all URL content..."
        },
        {
            text: "Analyzing document..."
        },
        {
            text: "Analyzing object coverage..."
        },
        {
            text: "Optimizing indirect objects..."
        },
        {
            text: "Setting page layout..."
        },
        {
            text: "Adjusting conversion settings..."
        },
        // {
        //     text: "Calling REST API..."
        // },
        {
            text: "Converting..."
        },
    ];



    const progressTextRef = useRef(progressText);

    useEffect(() => {
        progressTextRef.current = progressText;
    }, [progressText]);

    useEffect(() => {
        let interval;
        const updateInterval = () => {
            if (interval) {
                clearInterval(interval);
            }
            const intervalTime = pdfServiceStore.progress < 100 ? 100 : 1000;
            interval = setInterval(() => {
                if (pdfServiceStore.progress >= 100 && !pdfServiceStore.isLoading) {
                    clearInterval(interval);
                    return;
                }

                if (pdfServiceStore.progress < 100) {
                    pdfServiceStore.progress += 1;
                    const messageIndex = messages.findIndex(msg => msg.progress === pdfServiceStore.progress);
                    if (messageIndex !== -1) {
                        setProgressText(messages[messageIndex].text);
                    }
                } else if (pdfServiceStore.isLoading) {
                    const messageIndex = middleMessages.findIndex(msg => msg.text.trim().toLowerCase() === progressTextRef.current.trim().toLowerCase());
                    if (messageIndex === -1) {
                        pdfServiceStore.progress += 1;
                        setProgressText(middleMessages[0].text);
                    } else if ((messageIndex + 1) < middleMessages.length) {
                        setProgressText(middleMessages[messageIndex + 1].text)
                    } else {
                        clearInterval(interval);
                    }
                }
            }, intervalTime);
        };
        updateInterval();

        return () => clearInterval(interval);
    }, [pdfServiceStore.progress, pdfServiceStore.isLoading, messages]);


    return (
        <div className="pdf-progress-container d-flex align-items-center justify-content-center py-3 mt-4">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-auto text-center pt-3">
                        {pdfServiceStore.isLoading ? (
                            <>
                                <Spinner size="7rem" color="danger" />
                                {
                                    showProgressText && <><span className={`spinner-progress-count ${notify ? 'email_notify' : ''}`}>{pdfServiceStore.progress <= 100 ? `${pdfServiceStore.progress}%` : ''}</span><h6 className="mt-2 progress-text text-white">{progressText} </h6></>
                                }

                                {
                                    notify && <div className='text-center'>
                                        <span className='text-secondary responsive-text'>You'll be notified via email when the PDF is ready.
                                            <span className=' text-secondary responsive-text'>
                                                You can continue browsing safely.  <a href='#' className='mx-2' onClick={handleStartOver} >
                                                    Generate new!
                                                </a>
                                            </span>
                                        </span>

                                        <span className='text-secondary d-block text-center p-0 m-0 responsive-text' style={{ fontWeight: 'bold', color: '#ff0000' }}>Note: you may find the generated PDFs in the dashboard.</span>
                                    </div>
                                }

                            </>
                        ) : (<>
                                  <i className="fas fa-check-circle fa-3x animated-icon" style={{ color: 'green', fontSize: '7rem' }}></i>
                            <h6 className="mt-2 progress-text text-white">Completed!</h6>
                                {
                                    !auth.isLoggedIn() && (
                                        <>
                  
                            <div className='text-center'>
                                <span className='text-secondary responsive-text'>
                                    <span className=' text-secondary d-block responsive-text'>
                                        The link will expire in 30 minutes. If the user wants to keep their files on the server, they need to get the paid services.  <a href='auth/sign-up' className='mx-2' >
                                            Signup now!
                                        </a>
                                    </span>
                                </span>
                            </div>
                        </>
                                )
                            }
                            </>)
                        }

                    </div>
                </div>
            </div>
        </div>
    );




}

export default observer(PdfProgress);
