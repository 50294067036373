import React, { useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import auth from "../../../shared/mobx/auth-state";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../components/bootstrap/Button';

import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import LoginHeader from './LoginHeader';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
const ResetPassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const token = new URLSearchParams(location.search).get('token');
    return (
        <PageWrapper
            isProtected={false}
            title={true ? 'Sign Up' : 'Login'}
            className={classNames({ 'bg-dark': !true, 'bg-light': true })}>
            <Page className='p-0'>
                <div className='row h-100 align-items-center justify-content-center'>
                    <div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
                        <Card className='shadow-3d-dark' data-tour='login-page'>
                            <CardBody>
                                <LoginHeader isNewUser={true} isResetPassword={true} />
                                <Formik
                                    initialValues={{ password: '', confirmPassword: '' }}
                                    validationSchema={Yup.object({
                                        password: Yup.string()
                                            .min(6, 'Password should be at least 6 characters long')
                                            .required('Password is a required field')
                                            .matches(/^[a-zA-Z0-9]{3,30}$/, 'Password must contain only letters and numbers and be between 3 and 30 characters long'),
                                        confirmPassword: Yup.string()
                                            .oneOf([Yup.ref('password'), ''], 'Passwords must match')
                                            .required('Confirm Password is required'),
                                    })}
                                    onSubmit={async (values, { setSubmitting }) => {
                                        setIsLoading(true);
                                        try {
                                            const { status } = await auth.resetPassword(token, values);
                                            if (status == 1) {
                                                setTimeout(()=>{
                                                    navigate('/auth/login');
                                                },1000)
                                            }
                                        } catch (error) {
                                            console.error('Reset Password failed:', error);
                                        }
                                        setIsLoading(false);
                                        setSubmitting(false);
                                    }}
                                >
                                    {({ values, touched, errors, isValid, handleChange, handleBlur, isSubmitting, setErrors }) => (
                                        <Form>
                                            <div className="row">
                                                <div className="col-12">
                                                    <FormGroup className='mb-2' label="Password" isFloating >
                                                        <Input
                                                            value={values.password}
                                                            isTouched={touched.password}
                                                            invalidFeedback={errors.password}
                                                            isValid={isValid}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            onFocus={() => {
                                                                setErrors({});
                                                            }}
                                                            type='password'
                                                            name='password'
                                                        />
                          
                                                    </FormGroup>
                                                </div>
                                                <div className="col-12">
                                                    <FormGroup label="Confirm Password" isFloating >
                                                        <Input
                                                            value={values.confirmPassword}
                                                            isTouched={touched.confirmPassword}
                                                            invalidFeedback={errors.confirmPassword}
                                                            isValid={isValid}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            onFocus={() => {
                                                                setErrors({});
                                                            }}
                                                            type='password'
                                                            name='confirmPassword'
                                                        />
                                                      
                                                    </FormGroup>
                                                </div>
                                                <div className='col-12'>
                                                    <Button
                                                        type='submit'
                                                        className='btn btn-info w-100 py-3 my-3'
                                                        isDisable={isSubmitting || isLoading}>
                                                        {isSubmitting || isLoading ? "Submitting..." : "Reset Password"}
                                                    </Button>
                                                </div>
                                                <div className="col-12">
                                                    <div className="text-center">
                                                        <Link to="/auth/login" className="text-decoration-none">
                                                            Remembered your password? Login here.
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </Page>
        </PageWrapper>
    );
};

export default ResetPassword;
