//@ts-nocheck
import React, { useState } from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import Button from '@mui/material/Button';
import Input from '../../../components/bootstrap/forms/Input';
import moment, { Moment } from 'moment';

function DateRangePickerComponent({setStartDate, setEndDate, startDate, endDate}) {

    const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null);


    return (
        <div className='text-end mb-2 p-3' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '10px', alignItems: 'center' }}>
            {/* <Input
                style={{ width: '200px' }}
                className='form-control'
                onClick={() => setFocusedInput('startDate')}
                value={getDisplayValue()}
                readOnly
                
            /> */}
            <DateRangePicker
                startDate={startDate} 
                startDateId="your_unique_start_date_id" 
                endDate={endDate} 
                endDateId="your_unique_end_date_id" 
                onDatesChange={({ startDate, endDate }) => { 
                           setStartDate(startDate); 
                   setEndDate(endDate);
                }} 
                focusedInput={focusedInput} 
                onFocusChange={focusedInput => setFocusedInput(focusedInput)} 
                isOutsideRange={() => false} // Allow all dates to be selectable
                showClearDates={true} // Show a clear button on the date picker
            />
         
        </div>
    );
}

export default DateRangePickerComponent;
