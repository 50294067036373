// @ts-nocheck
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import auth from "../../../shared/mobx/auth-state";
import LoginHeader from './LoginHeader';
import { observer } from 'mobx-react';
interface ILoginProps {
	isSignUp?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const returnUrl = new URLSearchParams(location.search).get('returnUrl');



	const { darkModeStatus } = useDarkMode();

	const [signInPassword, setSignInPassword] = useState<boolean>(true);



	const handleOnClick = useCallback(() => navigate('/'), [navigate]);

	// const usernameCheck = (username: string) => {
	// 	return !!getUserDataWithUsername(username);
	// };

	const [isLoading, setIsLoading] = useState<boolean>(false);

	// const passwordCheck = (username: string, password: string) => {
	// 	return getUserDataWithUsername(username).password === password;
	// };

	useEffect(() => {
		const preActionFun = () => {
			const preAction = new URLSearchParams(location.search).get('preaction');
			if (auth.isLoggedIn() && auth.currentUser && ["logout"].includes(preAction)) {
				auth.logout();
			}

			if (auth.isLoggedIn() && auth.currentUser && auth.activeRole) {
				auth.navigateToRole(navigate);
			}
		}
		preActionFun();
	}, []);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			email: "",
			password: "",
		},
		validate: (values) => {
			const errors: { email?: string; password?: string } = {};

			if (!values.email) {
				errors.email = 'Required';
			}

			if (!values.password) {
				errors.password = 'Required';
			}


			return errors;
		},
		validateOnChange: false,
		onSubmit: async (values) => {
			if (isLoading) return;
			setIsLoading(true);
			const { status } = await auth.doLogin(values);

			if (status === 1) {
				await auth.navigateToRole(navigate, returnUrl)
			} else {
				//formik.setFieldError('email', 'No such user found in the system.');
			}
			setIsLoading(false);

		},
	});


	// const handleContinue = async () => {
	// 	setIsLoading(true);
	// 	const { status } = await auth.doLogin(values);

	// 	if (status === 1) {
	// 		await auth.navigateToRole(navigate)
	// 	} else {
	// 		formik.setFieldError('email', 'No such user found in the system.');
	// 	}
	// 	setIsLoading(false);
	// };

	return (
		<PageWrapper
			isProtected={false}
			title={isSignUp ? 'Sign Up' : 'Login'}
			className={classNames({ 'bg-dark': !true, 'bg-light': true })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}
										aria-label='Facit'>
										<Logo width={200} />
									</Link>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={isSignUp}
												className='rounded-1 w-100'
												size='lg'
												tag='a'
												to='/auth/login'
											// onClick={() => {
											// 	setSignInPassword(false);
											// 	setisSignUp(!isSignUp);
											// }}>
											>
												Login
											</Button>
										</div>
										<div className='col'>
											<Button
												tag='a'
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!isSignUp}
												className='rounded-1 w-100'
												size='lg'
												// onClick={() => {
												// 	setSignInPassword(false);
												// 	setisSignUp(!isSignUp);
												// }}>
												to='/auth/sign-up'
											>
												Sign Up
											</Button>
										</div>
									</div>
								</div>

								<LoginHeader isNewUser={isSignUp} />

								{/* <Alert isLight icon='Lock' isDismissible>
									<div className='row'>
										<div className='col-12'>
											<strong>Username:</strong> {USERS.JOHN.username}
										</div>
										<div className='col-12'>
											<strong>Password:</strong> {USERS.JOHN.password}
										</div>
									</div>
								</Alert> */}
								<form className='row g-4'>
									{isSignUp ? (
										<>
											<div className='col-12 mb-2'>
												<FormGroup
													id='signup-email'
													isFloating
													label='Your email'>
													<Input type='email' autoComplete='email' />
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-name'
													isFloating
													label='Your name'>
													<Input autoComplete='given-name' />
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-surname'
													isFloating
													label='Your surname'>
													<Input autoComplete='family-name' />
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-password'
													isFloating
													label='Password'>
													<Input
														type='password'
														autoComplete='password'
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<Button
													color='info'
													className='w-100 py-3'
													onClick={handleOnClick}>
													Sign Up
												</Button>
											</div>
										</>
									) : (
										<>
											<div className="col-12">
												<FormGroup
													id='email'
													isFloating
													label='Your Email'>
													<Input
														autoComplete='username'
														value={formik.values.email}
														isTouched={formik.touched.email}
														invalidFeedback={
															formik.errors.email
														}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onKeyDown={(event) => {
															if (event.keyCode === 13) {
																formik.handleSubmit();
															}
														}}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({});
														}}

													/>
												</FormGroup>

											</div>
											<div className='col-12'>
												<FormGroup
													id='password'
													isFloating
													label='Password'
													className={classNames({
														'd-none': !signInPassword,
													})}>
														<Input
															
														type='password'
														autoComplete='current-password'
														value={formik.values.password}
														isTouched={formik.touched.password}
														invalidFeedback={
															formik.errors.password
														}
														validFeedback='Looks good!'
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onKeyDown={(event) => {
															if (event.keyCode === 13) {
																formik.handleSubmit();
															}
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>

													<Button
														isDisable={isLoading}
													color='warning'
													className='w-100 py-3'
													onClick={formik.handleSubmit}>
													{!isLoading ? <>Login</> : <>Please wait...</>}
												</Button>

											</div>
										</>
									)}

									{/* BEGIN :: Social Login */}
									{true && (
										<>
											{/* <div className='col-12 mt-3 text-center text-muted'>
												OR
											</div> */}
											<div className='col-12 mt-3'>
												<Button
													isOutline
													tag="a"
													to="/auth/forgot-password"
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}

												>
													Forgot Password? click here.
												</Button>
											</div>
											<div className="text-center">
												<Link to="/auth/resend-verification-email" className="text-decoration-none">
													Resend verification email? click here.
												</Link>
											</div>

										</>
									)}
									{/* END :: Social Login */}
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': isSignUp,
									'link-dark': !isSignUp,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': isSignUp,
									'link-dark': !isSignUp,
								})}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default observer(Login);
