//@ts-nocheck
import React, { useState } from 'react';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Chart, { IChartOptions } from '../../../components/extras/Chart';

const BarChart = ({name, title, data, categories}) => {
	const [usersRegisteredData] = useState<IChartOptions>({
		series: [
			{
				name: name,
				data: data??[]
			},
		],
		options: {
			chart: {
				height: 350,
				type: 'bar',
			},
			plotOptions: {
				bar: {
					dataLabels: {
						position: 'top', // top, center, bottom
					},
				},
			},
			dataLabels: {
				enabled: true,
				formatter(val) {
					return `${val}`; 
				},
				offsetY: -20,
				style: {
					fontSize: '12px',
					colors: ['#304758'],
				},
			},
			xaxis: {
				categories:categories??[],
				position: 'top',
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
				crosshairs: {
					fill: {
						type: 'gradient',
						gradient: {
							colorFrom: '#D8E3F0',
							colorTo: '#BED1E6',
							stops: [0, 100],
							opacityFrom: 0.4,
							opacityTo: 0.5,
						},
					},
				},
				tooltip: {
					enabled: true,
				},
			},
			yaxis: {
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
				labels: {
					show: false,
					formatter(val) {
						return `${val} users`; // Changed to show user count
					},
				},
			},
			title: {
				text: title,
				floating: true,
				offsetY: 330,
				align: 'center',
				style: {
					color: '#444',
				},
			},
		},
	});
	return (
		<Card stretch>
		<CardHeader>
			<CardLabel icon='BarChart'>
				<CardTitle>
					{name} 
					{/* <small>by Weekday</small> */}
				</CardTitle>
				{/* <CardSubTitle>Chart</CardSubTitle> */}
			</CardLabel>
		</CardHeader>
		<CardBody>
			<Chart
				series={usersRegisteredData.series}
				options={usersRegisteredData.options}
				type='bar'
				height={350}
			/>
		</CardBody>
	</Card>
	);
};

export default BarChart;
