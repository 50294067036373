import React, { useState } from 'react';
import Button from '../../../components/bootstrap/Button';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../components/bootstrap/Modal';

import OffCanvas, {
    OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';
import Spinner from '../../../components/bootstrap/Spinner'; // Make sure to have a Spinner component or use an equivalent
import ApiKeyComponent from './ApikeyComponent';

interface CreateApiKeyModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    generatedApiKey: string;
    handleCreateApiKey: () => Promise<void>; // Ensure this is async or handles promises if generating key takes time
    handleCopyApiKey: (apiKey: string) => void;
}

const CreateApiKeyModal: React.FC<CreateApiKeyModalProps> = ({
    isOpen,
    setIsOpen,
    generatedApiKey,
    handleCreateApiKey,
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleGenerateClick = async () => {
        setIsLoading(true);
        await handleCreateApiKey();
        setIsLoading(false);
    };

    return (
        <Modal
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            titleId='createApiKey'
            isCentered
            isScrollable
            size='lg'>
            <ModalHeader setIsOpen={setIsOpen}>
                <OffCanvasTitle id='createApiKey'>Create API Key</OffCanvasTitle>
            </ModalHeader>
            <ModalBody>
                <div className='row g-4'>
                    <div className='col-12'>
                        {
                            !generatedApiKey && (<>
                                <p>Generate a new API key:</p>
                                <Button
                                    color='primary'
                                    className='w-100 mb-3'
                                    onClick={handleGenerateClick}
                                    isDisable={isLoading}>
                                    {isLoading ? (
                                        <>Generating...  <Spinner size="15px" /></> // Use a Spinner or any loading indicator
                                    ) : (
                                        'Generate Key'
                                    )}
                                </Button>
                            </>)
                        }
                        {generatedApiKey && (
                            <>
                                <p>Generated API Key:</p>
                                <ApiKeyComponent apiKey={generatedApiKey} />
                            </>
                        )}
                    </div>
                </div>
            </ModalBody>
            {/* <ModalFooter className='bg-transparent'>
                <Button
                    color='primary'
                    className='w-100'
                    onClick={() => setIsOpen(false)}>
                    Close
                </Button>
            </ModalFooter> */}
        </Modal>
    );
};

export default CreateApiKeyModal;
