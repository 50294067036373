//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { observer } from 'mobx-react-lite';
import settingsState from '../../../shared/mobx/settings-state';
import { toTitleCase } from "../../../helpers/helpers";
import Modal from '../../../components/bootstrap/Modal';
import Button from '../../../components/bootstrap/Button';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardActions, CardBody, CardFooter, CardHeader, CardLabel, CardSubTitle, CardTitle } from '../../../components/bootstrap/Card';

import * as Yup from 'yup';
import Checks from '../../../components/bootstrap/forms/Checks';

const createValidationSchema = (configSettings, isUnlimited) => {
    let schemaFields = {};

    Object.entries(configSettings).forEach(([configGroup, configValues]) => {
        let groupSchema = {};
        Object.keys(configValues).forEach(fieldName => {
            if (fieldName === 'maximum_pages_allowed' && isUnlimited) {
                groupSchema[`${fieldName}`] = Yup.string().nullable();  // No validation when unlimited
            } else {
                groupSchema[`${fieldName}`] = Yup.string().required(`${toTitleCase(fieldName)} is required`);
            }
        });
        schemaFields[configGroup] = Yup.object().shape(groupSchema);
    });

    return Yup.object().shape(schemaFields);
};

const ConfigForm = observer(() => {
    const [loading, setLoading] = useState(false);
    const [isUnlimited, setUnlimited] = useState(false);
    const [inputTypes, setInputTypes] = useState({
        'daily_limit': 'number',
        'email_from': 'email',
        'port': 'number',
        'maximum_pages_allowed': 'number'
    });

    const validationSchema = React.useMemo(() => createValidationSchema(settingsState.configSettings, isUnlimited), [settingsState.configSettings, isUnlimited]);

    const toggleUnlimited = () => {
        setInputTypes({
            ...inputTypes,
            maximum_pages_allowed: isUnlimited ? 'number' : 'text'
        });
        setUnlimited(!isUnlimited);

        settingsState.configSettings.pdf_config.maximum_pages_allowed = isUnlimited ? 3 : 'unlimited';
    };

    const handleMyChange = (event) => {
        const { name, value, type, checked } = event.target;
        const applyValue = (obj, path, newValue) => {
            const keys = path.split('.');
            const lastKey = keys.pop();
            const lastObj = keys.reduce((acc, key) => acc[key] = acc[key] || {}, obj);
            if (type === "checkbox") {
                lastObj[lastKey] = checked;
            } else {
                lastObj[lastKey] = newValue;
            }
            return obj;
        };

        const updateSettings = applyValue(settingsState.configSettings, name, value);
        settingsState.configSettings = { ...updateSettings };
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await settingsState.getSettingsAdmin();
            setUnlimited(settingsState?.configSettings.pdf_config?.maximum_pages_allowed === 'unlimited');
            setLoading(false);
        };
        fetchData();
    }, []);

    return (
        <Formik
            initialValues={settingsState.configSettings}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                settingsState.save({
                    ...values,
                    pdf_config: {
                        ...values.pdf_config,
                        maximum_pages_allowed: isUnlimited ? 'unlimited' : values.pdf_config.maximum_pages_allowed.toString()
                    }
                }).catch(console.error).finally(() => setSubmitting(false));
            }}
        >
            {({ errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, values }) => (
                <Form onSubmit={handleSubmit} className="row">
                    {Object.entries(values).map(([configGroup, configValues]) => (
                        <div className="col-sm-12" key={configGroup}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="div" className="h5">{toTitleCase(configGroup)}</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    {Object.entries(configValues).map(([fieldName, fieldValue]) => (
                                        <React.Fragment key={fieldName}>
                                            {fieldName === 'maximum_pages_allowed' && (
                                                <Checks
                                                    id="status"
                                                    name='usageStatus'
                                                    className="status mb-6"
                                                    type="switch"
                                                    label='Unlimited Pages'
                                                    style={{
                                                        marginLeft: '-25px'
                                                    }}
                                                    onChange={toggleUnlimited}
                                                    checked={isUnlimited}
                                                />
                                            )}
                                            {(fieldName !== 'maximum_pages_allowed' || !isUnlimited) && (
                                                <FormGroup label={toTitleCase(fieldName)} className='mb-2' isFloating>
                                                    <Input
                                                        className='mt-3'
                                                        type={inputTypes[fieldName] ?? 'text'}
                                                        name={`${configGroup}.${fieldName}`}
                                                        placeholder={toTitleCase(fieldName)}
                                                        disabled={isSubmitting || loading || (isUnlimited && fieldName === 'maximum_pages_allowed')}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={loading ? "Loading..." : fieldValue}
                                                    />
                                                    {touched[configGroup]?.[fieldName] && errors[configGroup]?.[fieldName] && (
                                                        <div className="text-danger">{errors[configGroup][fieldName]}</div>
                                                    )}
                                                </FormGroup>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </CardBody>
                            </Card>
                        </div>
                    ))}
                    <div className="col-sm-12 text-end mx-2">
                        <Button isDisable={isSubmitting || loading} type="submit" className="w-100" color="primary">
                            {isSubmitting ? <>Please wait...</> : <>Save</>}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
});

const SettingsPage = () => (
    <PageWrapper title="Settings">
        <Page>
            <div className="row">
                <div className='col-12'>
                    <div className='display-6 fw-bold py-2'>Settings</div>
                </div>
                <div className="col-12 mb-3">
                    <ConfigForm />
                </div>
            </div>
        </Page>
    </PageWrapper>
);

export default observer(SettingsPage);
