//@ts-nocheck
import React, { useState, useRef, useEffect } from 'react';
import logoImage from '../images/logo.svg'; // You can replace this with your new logo path
import auth from '../../shared/mobx/auth-state';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

const dropDownNavs = [
  { text: "Dashboard", path: "/dashboard", access: ["user", "admin"] },
  { text: "My Profile", path: "/my-profile", access: ["user", "admin"] },
  { text: "Subscription Details", path: "/subscriptions", access: ["user"] },
  { text: "Subscription History", path: "/user-subscription-history", access: ["user"] },
  { text: "Logout", path: "/auth/login?preaction=logout", access: ["user", "admin"] },
];

const Navbar: React.FC = ({ isIndex }) => {
  const [isIconOpen, setIconOpen] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIconOpen(false);
    }
  };

  useEffect(() => {
    if (isIconOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isIconOpen]);

  const handleNavigation = (path) => (event) => {
    event.preventDefault();
    navigate(path);
  };

  return (
    <header className="header">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-3 col-sm-12 col-12">
            <div className="logo">
              <a href="/">
                <img src={logoImage} className="img-responsive" alt="Logo" />
              </a>
            </div>
          </div>
          <div className="col-md-7 col-sm-12 col-12">
            {/* Navbar */}
            <nav className="navbar navbar-expand-lg justify-content-center">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href={isIndex ? "#home" : "/"}>
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href={isIndex ? "#benefit" : "/#benefit"}>
                      Benefits
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href={isIndex ? "#benefit" : "/#"}>
                      Reviews
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href={isIndex ? "#benefit" : "/#"}>
                      pricing
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href={isIndex ? "#faq" : "/#faq"}>
                      FAQs
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href={isIndex ? "#contact" : "/#contact"}>
                      Contact
                    </a>
                  </li>
                  <ul className="login-menu">
                  {dropDownNavs.map(
                    (item) =>
                      item.access.includes(auth.activeRole) && (
                        <li key={item.text} className="nav-items">
                          <a href={item.path} className="nav-links" onClick={handleNavigation(item.path)}>
                            {item.text}
                          </a>
                        </li>
                      )
                  )}
                  </ul>
                </ul>
              </div>
            </nav>
          </div>
          <div className="col-md-2 col-sm-0 col-0">
            <div className="sign-btn">
              {auth.isLoggedIn() ? (
                <button
                  ref={dropdownRef}
                  onClick={() => setIconOpen(!isIconOpen)}
                  className={`circle-button ${isIconOpen ? 'toggleUserIcon' : ''}`}
                  style={{ backgroundColor: auth.currentUser?.profileImage ? 'transparent' : undefined }}
                >
                  {auth.currentUser?.profileImage ? (
                    <img
                      width="40"
                      height="40"
                      style={{ width: '40px', height: '40px', display: 'block', borderRadius: 'inherit' }}
                      src={auth.currentUser?.profileImage}
                      alt="Profile"
                    />
                  ) : (
                    <i className="fas fa-user user-icon"></i>
                  )}
                  {isIconOpen && (
                    <ul className="dropdown-menu">
                      {dropDownNavs.map(
                        (item) =>
                          item.access.includes(auth.activeRole) && (
                            <li key={item.text} className="dropdown-item">
                              <a href={item.path} onClick={handleNavigation(item.path)}>
                                {item.text}
                              </a>
                            </li>
                          )
                      )}
                    </ul>
                  )}
                </button>
              ) : (
                <Link className="nav-link" to="/auth/login">
                  Sign In
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default observer(Navbar);
