//@ts-nocheck
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Button from '../../../components/bootstrap/Button';
import Page from '../../../layout/Page/Page';
import Card, {
  CardActions,
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from '../../../components/bootstrap/Card';
import PaginationButtons, {
  dataPagination,
  PER_COUNT,
} from '../../../components/PaginationButtons';
import ConfirmationModal from '../ConfirmationModal';
import useDarkMode from '../../../hooks/useDarkMode';
import userState from '../../../shared/mobx/user-state';
import Spinner from '../../../components/bootstrap/Spinner';
import Checks from '../../../components/bootstrap/forms/Checks';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import apiKeyState from '../../../user/mobx/apiKeyState';
import keyUsage from '../api-key/keyUsage';
import LoaderWrapper from '../../../layout/Wrapper/LoaderWrapper';
import AddUserModal from './AddUserModal';


const UsersPage = () => {
  const { darkModeStatus } = useDarkMode();
  const [users, setUsers] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [perPage, setPerPage] = useState(10); // Default items per page
  const [totalRecords, setTotalRecords] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refreshValue, setRefresh] = useState(0);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [user, setUser] = useState<any>({})

  const [deleteLoader, setDeleteLoader] = useState(false);

  const handleAddNew = () => {
    setUser({});
    handleOpenModal();
  }
  const handleUpdateUser = (user) => {
    setUser(user);
    handleOpenModal();
  }

  const refresher = () => {
    setUser({});
    setRefresh(refreshValue + 1);
  }
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const d = await userState.getuserList({ per_page: perPage, page: currentPage }); // Modify this according to how your function accepts currentPage & perPage
      setUsers(d?.data);
      console.log(d);
      setTotalRecords(d?.total_records);
      setLoading(false);
      // Adjust according to your actual API response structure
    };
    fetchData();
  }, [currentPage, perPage, refreshValue]);

  const setLoader = (item, status = false) => {
    const _u = users?.map(i => i.id === item.id ? { ...i, loading: status } : i)
    setUsers(_u);
  }
  const toggleUserStatus = async (item) => {
    await setLoader(item, true);
    await userState.toggleUserStatus(item);
    await setLoader(item);
    refresher();
  }



  const handleDelete = async (item) => {
    setUser(item);
    setConfirmationModal(true);

  }

  const setModalClose = () => {
    setUser({});
    setIsModalOpen(false);
  }


  const deleteAction = async () => {
    setDeleteLoader(true);
    await userState.deleteUser(user.id);
    setUser({});
    setConfirmationModal(false);
    refresher();
    setDeleteLoader(false);
  }

  return (
    <PageWrapper title="Users">
      <Page container='fluid'>
        <div className='row'>
          <div className='col-12'>
            <div className='display-6 fw-bold py-2'>Users</div>
          </div>
          <div className='col-12'>
            <Card>
              <CardHeader borderSize={1}>
                <CardLabel iconColor='info'>
                  <CardTitle tag='div' className='h5'>
                    Users
                  </CardTitle>
                </CardLabel>
                <CardActions>
                  <Button
                    onClick={handleOpenModal}
                    color='info'>
                    Add New User
                  </Button>
                </CardActions>
              </CardHeader>
              <CardBody className='table-responsive'>
                <LoaderWrapper isLoading={isLoading} data={users}>
                  <>
                    <table className='table table-modern'>
                      <thead>
                        <tr>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                          <th>Creation Date</th>
                          <th>Role</th>
                          <th>Package Name</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((item) => (
                          <tr key={item.id}>
                            <td>{item.firstName}</td>
                            <td>{item.lastName}</td>
                            <td>{item.email}</td>
                            <td>{dayjs(item.createdAt).format('MM-DD-YYYY')}</td>
                            <td>{item.role.name}</td>
                            <td>{item.package_name || 'N/A'}</td>
                            <td>
                              {
                                item.loading ? <><Spinner size="12px" color="primary" /></>
                                  : <Checks
                                    id="status"
                                    name='usageStatus'
                                    className="status"
                                    type="switch"

                                    onChange={() => toggleUserStatus(item)}
                                    checked={item.status === "Active"}
                                  />
                              }

                            </td>
                            <td>
                              <Dropdown>
                                <DropdownToggle hasIcon={false}>
                                  <Button
                                    icon='MoreHoriz'
                                    aria-label='More options'
                                  />
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd>

                                  <DropdownItem>
                                    <Button
                                      tag="a"
                                      to={`/users/details/${item.id}`}
                                      icon='Details'>
                                      View Subscriptions
                                    </Button>
                                  </DropdownItem>

                                  <DropdownItem>
                                    <Button
                                      tag="a"
                                      to={`/api-key-management/${item.id}`}
                                      icon='Details'>
                                        Api key management
                                    </Button>
                                  </DropdownItem>

                                  <DropdownItem>
                                    <Button onClick={() => handleUpdateUser(item)} icon='Edit'>
                                      Edit
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button onClick={() => handleDelete(item)} icon='Delete'>
                                      Delete
                                    </Button>
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>

                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <PaginationButtons
                      data={users}
                      label='users'
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                      perPage={perPage}
                      setPerPage={setPerPage}
                      totalItems={totalRecords}
                    />
                  </>
                </LoaderWrapper>
              </CardBody>
            </Card>

          </div>
        </div>
      </Page>
      {isModalOpen ? (
        <AddUserModal refresher={refresher} user={user} isOpen={isModalOpen} onClose={setModalClose} />
      ) : null}
      <ConfirmationModal
        isOpen={confirmationModal} // Use the renamed state variable
        setIsOpen={setConfirmationModal} // Use the updated setter function
        loading={deleteLoader}
        title="Confirmation?"
        message="Are you sure you want to delete the user?"
        handleAction={deleteAction}
      />
    </PageWrapper>
  );
};

export default UsersPage;
