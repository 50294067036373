import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import auth from "../../../shared/mobx/auth-state";
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import LoginHeader from './LoginHeader';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import useDarkMode from '../../../hooks/useDarkMode';

const VerifyEmail = () => {

    const [isLoading, setIsLoading] = useState(false);
    const { darkModeStatus } = useDarkMode();
    const navigate = useNavigate();
    return (
        <PageWrapper
            isProtected={false}
            title={true ? 'Email Verification' : 'Login'}
            className={classNames({ 'bg-dark': !true, 'bg-light': true })}>
            <Page className='p-0'>
                <div className='row h-100 align-items-center justify-content-center'>
                    <div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
                        <Card className='shadow-3d-dark' data-tour='login-page'>
                            <CardBody>
                                {/* <div className='text-center my-5'>
                                <Link
                                    to='/'
                                    className={classNames(
                                        'text-decoration-none  fw-bold display-2',
                                        {
                                            'text-dark': !darkModeStatus,
                                            'text-light': darkModeStatus,
                                        },
                                    )}
                                    aria-label='Facit'>
                                    <Logo width={200} />
                                </Link>
                            </div>
                            <div
                                className={classNames('rounded-3', {
                                    'bg-l10-dark': !darkModeStatus,
                                    'bg-dark': darkModeStatus,
                                })}>
                                <div className='row row-cols-2 g-3 pb-3 px-3 mt-0 mb-2'>
                                    <div className='col'>
                                        <Button
                                            color={darkModeStatus ? 'light' : 'dark'}
                                            isLight={true}
                                            className='rounded-1 w-100'
                                            size='lg'
                                            tag='a'
                                            to='/auth/login'
                                        // onClick={() => {
                                        // 	setSignInPassword(false);
                                        // 	settrue(!true);
                                        // }}>
                                        >
                                            Login
                                        </Button>
                                    </div>
                                    <div className='col'>
                                        <Button
                                            tag='a'
                                            color={darkModeStatus ? 'light' : 'dark'}
                                            isLight={!true}
                                            className='rounded-1 w-100'
                                            size='lg'
                                            // onClick={() => {
                                            // 	setSignInPassword(false);
                                            // 	settrue(!true);
                                            // }}>
                                            to='/auth/sign-up'
                                        >
                                            Sign Up
                                        </Button>
                                    </div>
                                </div>
                            </div> */}

                                <LoginHeader isVerifyEmail={true} />

                                <Formik
                                    initialValues={{ email: '' }}
                                    validationSchema={Yup.object({
                                        email: Yup.string().email('Email must be a valid email').required('Email is a required field'),
                                    })}
                                    onSubmit={async (values, { setSubmitting }) => {
                                        setIsLoading(true);
                                        
                                        try {
                                           const status =  await auth.doVerifyEmail(values);
                                            if(status) {
                                              setTimeout(()=>{
                                                navigate('/auth/login');
                                              },2000);
                                            }
                                        } catch (error) {
                                            console.error('Forgot Password failed:', error);
                                        }
                                        setIsLoading(false);
                                        setSubmitting(false);
                                    }}
                                >
                                    {({ values, touched, errors, isValid, handleChange, handleBlur, isSubmitting, setErrors }) => (
                                       
                                        <Form>
                                            <div className="row">
                                                <div className="col-12">
                                                <FormGroup label="Email" isFloating >
                                                <Input
                                                    value={values.email}
                                                    isTouched={touched.email}
                                                    invalidFeedback={errors.email}
                                                    isValid={isValid}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    onFocus={() => {
                                                        setErrors({});
                                                    }}
                                                    type='email'
                                                    autoComplete='email'
                                                    name='email'
                                                />
                                               
                                            </FormGroup>
                                                </div>
                                                <div className='col-12'>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-info w-100 py-3 my-3'
                                                        disabled={isSubmitting || isLoading}>
                                                        {isSubmitting || isLoading ? "Submitting..." : "Submit"}
                                                    </button>
                                                </div>

                                                <div className="col-12">
                                                    
                                            <div className="text-center">
                                                <Link to="/auth/login" className="text-decoration-none">
                                                    Already verified? Login here.
                                                </Link>
                                            </div>
                                                </div>

                                            </div>




                                        </Form>
                                    )}
                                </Formik>
                            </CardBody>
                        </Card>
                    </div>
                </div>

            </Page>
        </PageWrapper>
    );
};

export default VerifyEmail;
